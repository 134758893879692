// @ts-strict-ignore
import { Transition } from '@headlessui/react'
import clsx from 'clsx'
import { ReactNode, useState } from 'react'
import { CopyToClipboard as Copy } from 'react-copy-to-clipboard'

interface CopyToClipboardProps {
  onCopy?: () => void
  className?: string
  text: string
  children: ReactNode
}

function CopyToClipboard({ children, text, className, onCopy }: CopyToClipboardProps) {
  const classes = clsx('absolute -top-12 left-0 right-0 z-50 m-auto grid place-content-center', className)
  const [showCopied, setShowCopied] = useState(false)
  return (
    <Copy
      text={text}
      onCopy={() => {
        setShowCopied(true)
        setTimeout(() => setShowCopied(false), 2000)
        onCopy?.()
      }}
    >
      <div className='relative inline-grid place-items-center'>
        {children}
        <Transition
          show={showCopied}
          enter='transition-all duration-500'
          leave='transition-all duration-500'
          enterFrom='opacity-0 translate-y-2'
          enterTo='opacity-100 translate-y-0'
          leaveFrom='opacity-100 translate-y-0'
          leaveTo='opacity-0 -translate-y-2'
        >
          <div className={classes}>
            <div className='rounded-sm bg-white p-1 text-xs shadow-md'>Copied!</div>
          </div>
        </Transition>
      </div>
    </Copy>
  )
}

export default CopyToClipboard
