import { Checkbox, Description, Field, Label } from '@headlessui/react'
import clsx from 'clsx'
import React, { ForwardedRef, forwardRef } from 'react'

import { FormInputProps } from '../fields/types'

export type CheckboxInputProps = FormInputProps & {
  kind?: string
  className?: string
  wrapperClassName?: string
  children?: React.ReactNode
  disabled?: boolean
  onChange?: (checked: boolean, value?: string) => void
  defaultChecked?: boolean
  isPartiallyChecked?: boolean
  checked?: boolean
  value?: string
}

const CheckboxInput = (
  {
    name,
    label,
    description,
    kind,
    className,
    wrapperClassName,
    checked,
    isPartiallyChecked = false,
    children,
    value,
    hasErrors,
    onChange,
    ...rest
  }: CheckboxInputProps,
  ref: ForwardedRef<HTMLInputElement>,
) => {
  const inputClasses = clsx(
    className,
    'group block size-5 rounded border bg-white data-[checked]:bg-[#00568f]',
    'shrink-0 appearance-none p-0',
    !hasErrors && !rest.disabled && 'border-black focus:border-gray-500 focus:ring-gray-500',
    isPartiallyChecked && '-checked:absolute -checked:left-[4px] -checked:top-[-1px] -checked:content-[2014]',
    rest.disabled && 'cursor-not-allowed !border-[#ccc] !bg-[#ccc]',
    hasErrors && 'border-red',
  )

  return (
    <Field className={clsx('flex w-full items-center gap-3', wrapperClassName)}>
      <Checkbox
        ref={ref}
        checked={checked}
        value={value}
        onChange={(checked: boolean) => {
          onChange?.(checked, value)
        }}
        className={inputClasses}
        {...rest}
      >
        <svg className='stroke-white opacity-0 group-data-[checked]:opacity-100' viewBox='0 0 15 15' fill='none'>
          {isPartiallyChecked ? (
            <rect x='5' y='5' width='5' height='5' fill='white' />
          ) : (
            <path d='M3 8L6 11L11 3.5' strokeWidth={2} strokeLinecap='round' strokeLinejoin='round' />
          )}
        </svg>
      </Checkbox>

      <Label
        className={clsx(
          'flex w-full flex-col items-baseline text-sm font-semibold',
          rest.disabled && 'cursor-not-allowed',
          hasErrors && 'text-red',
        )}
      >
        {label}

        <Description className='text-neutral-8 w-full text-xs'>{description}</Description>
      </Label>
    </Field>
  )
}

export default forwardRef<HTMLInputElement, CheckboxInputProps>(CheckboxInput)
