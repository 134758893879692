import debug from 'debug'
import { decodeJwt, JWTPayload } from 'jose'
import { createContext, SetStateAction, useEffect, useState } from 'react'
import { useSetRecoilState } from 'recoil'
import _config from 'src/config'

import { captureException } from '../misc/sentry'
import { userState } from '../state'
import { refreshSession, VerifiedContact } from './cognito'

const logger = debug('app:auth')

export type AuthProviderType = 'COGNITO' | 'SSO'

export interface AuthStatus {
  userId: string | number
  email: string
  type: AuthProviderType
  idToken: string
  accessToken: { jwtToken: string; payload: JWTPayload }
  // Only for SSO, handled internally by amplify library for non-SSO now
  refreshToken?: string
  authenticated: boolean
  emailVerified: boolean
  timestamp?: number
  contactDetails: VerifiedContact
  mfa?: string[]
}

export const COGNITO_IDENTITY_KEY_PREFIX = 'CognitoIdentityId-'

export const USER_AUTH_KEY = 'UserAuth'

const DEFAULT_USER_AUTH: AuthStatus = Object.freeze({
  userId: 0,
  email: '',
  idToken: '',
  accessToken: {
    jwtToken: '',
    payload: {
      sub: '',
      email_verified: false,
      email: '',
      iss: '',
      'cognito:username': '',
      aud: '',
      event_id: '',
      token_use: '',
      auth_time: 0,
      exp: 0,
      iat: 0,
    },
  },
  authenticated: false,
  emailVerified: false,
  type: 'COGNITO',
  contactDetails: {},
  mfa: [],
})

const useAuthHandler = (initialAuthState: AuthStatus) => {
  const [auth, setAuth] = useState(initialAuthState)

  const setAuthStatus = (userAuth: AuthStatus) => {
    if (!userAuth.type) {
      console.warn('AuthStatus is missing auth type')
      // This is likely a stale user session, let's assume they're COGNITO
      userAuth.type = 'COGNITO'
    }
    window.localStorage.setItem(USER_AUTH_KEY, JSON.stringify(userAuth))
    setAuth(userAuth)
  }
  const setUnauthStatus = () => {
    window.localStorage.removeItem(USER_AUTH_KEY)
    setAuth(DEFAULT_USER_AUTH)
  }

  return {
    auth,
    setAuthStatus,
    setUnauthStatus,
  }
}

export const clearAuthAndRedirectLogin = () => {
  logger('Clearing auth and redirecting to login')
  window.localStorage.removeItem(USER_AUTH_KEY)
  window.location.href = '/login'
}

export const isTokenExpired = (token: string) => {
  try {
    if (!token || token.length === 0) {
      return true
    }
    // Return true if the token is expired or about to expire (in ~5s)
    const payload = decodeJwt(token)
    const tokenExpiration = payload.exp! * 1000
    return tokenExpiration < Date.now() - 5000
  } catch (e) {
    captureException(e)
    console.warn('Unable to parse JWT', e)
    return true
  }
}

export const getStoredUserAuth = (): AuthStatus => {
  const auth = window.localStorage.getItem(USER_AUTH_KEY)
  if (auth) {
    const authStatus = JSON.parse(auth) as AuthStatus
    if (isTokenExpired(authStatus.idToken)) {
      return DEFAULT_USER_AUTH
    }
    return authStatus
  }
  return DEFAULT_USER_AUTH
}

export interface AuthContext {
  auth: AuthStatus
  userId: string
  userAccountVerified: boolean
  setUserAccountVerified: (value: SetStateAction<boolean>) => void
  setUserId: (value: SetStateAction<string>) => void
  setAuthStatus: (authStatus: AuthStatus) => void
  setUnauthStatus: () => void
}

export const authContext = createContext<AuthContext>({
  auth: DEFAULT_USER_AUTH,
  userId: '',
  userAccountVerified: false,
  setUserAccountVerified: (value: SetStateAction<boolean>) => {},
  setUserId: (value: SetStateAction<string>) => {},
  setAuthStatus: (authStatus: AuthStatus) => {},
  setUnauthStatus: () => {},
})

const { Provider } = authContext

const FIVE_MINUTES_IN_SECONDS = 5 * 60
const getRefreshDelayInSeconds = (tokenExpiry: number) =>
  Math.max(tokenExpiry - Date.now() / 1000 - FIVE_MINUTES_IN_SECONDS, 0)

export default function AuthProvider({ children }: { children: React.ReactNode }) {
  const [userId, setUserId] = useState('')
  const [userAccountVerified, setUserAccountVerified] = useState(false)
  const { auth, setAuthStatus, setUnauthStatus } = useAuthHandler(getStoredUserAuth())
  const setUserState = useSetRecoilState(userState)

  useEffect(() => {
    if (auth.authenticated && auth.idToken) {
      const decoded = decodeJwt(auth.idToken)

      if (!decoded) {
        throw new Error("Couldn't decode JWT")
      }

      setUserState({
        ...auth,
        sub: decoded.sub,
      })

      const tokenExpiry = decoded.exp

      if (!tokenExpiry) {
        throw new Error("Couldn't get token expiry")
      }

      // Refresh token
      const refreshTokenTimeout = setTimeout(
        async () => {
          logger('AuthProvider attempting to refresh token now!')
          const newSession = await refreshSession()
          if (newSession) {
            setAuthStatus(newSession)
          }
        },
        getRefreshDelayInSeconds(tokenExpiry) * 1000,
      )

      // Cleanup so we only ever have the latest version of the interval and timeout running at any time
      return () => {
        clearTimeout(refreshTokenTimeout)
      }
    }
  }, [auth, setAuthStatus, setUserState])

  return (
    <Provider
      value={{
        auth,
        setAuthStatus,
        setUnauthStatus,
        userAccountVerified,
        setUserAccountVerified,
        userId,
        setUserId,
      }}
    >
      {children}
    </Provider>
  )
}
