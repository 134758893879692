import CopyToClipboard from '../actions/copy-to-clipboard'
import Icon from '../icons/icon'

interface CopyToClipboardProps {
  onCopy?: () => void
  className?: string
  text: string
  iconSize?: number
}

function CopyToClipboardButton({ text, className, onCopy, iconSize = 12 }: CopyToClipboardProps) {
  return (
    <CopyToClipboard text={text} className={className} onCopy={onCopy}>
      <button type='button' className='text-link inline-grid place-items-center' aria-label='Copy'>
        <Icon name='copy' size={iconSize} />
      </button>
    </CopyToClipboard>
  )
}

export default CopyToClipboardButton
