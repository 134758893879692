import * as Tooltip from '@radix-ui/react-tooltip'
import clsx from 'clsx'
import { ReactElement } from 'react'

function HoverableTooltip({
  disabled = false,
  contentClassName,
  content,
  asChild,
  autoMaxWidth,
  isModern = false, // TODO: think of better name for this (the distinction is that it's very noticeable panel, not a subtle little tooltip)
  ...props
}: {
  content: ReactElement<any, any> | string
  disabled?: boolean
  contentClassName?: string
  className?: string
  asChild?: boolean
  autoMaxWidth?: boolean
  isModern?: boolean
} & Tooltip.TooltipProps &
  Pick<Tooltip.TooltipContentProps, 'side' | 'sideOffset' | 'align' | 'avoidCollisions'>) {
  const { side, sideOffset, align, avoidCollisions, children, className, ...rest } = props
  if (disabled) {
    return <>{children}</>
  }
  return (
    <Tooltip.Provider>
      <Tooltip.Root delayDuration={50} {...rest}>
        <Tooltip.Trigger className={clsx('flex', className)} asChild={asChild}>
          {children}
        </Tooltip.Trigger>
        <Tooltip.Content
          side={side}
          sideOffset={sideOffset}
          align={align}
          avoidCollisions={avoidCollisions}
          // Nice for left/right side tooltips that might bump into viewport edge, especially if
          //  you really prefer to stay on the specified side
          style={autoMaxWidth ? { maxWidth: 'calc(var(--radix-tooltip-content-available-width) - 40px)' } : {}}
          className={clsx(
            'data-[state=closed]:animate-fadeOut z-50 whitespace-nowrap rounded-md bg-white text-xs shadow-xl',
            isModern && 'border-neutral-7 border-2 p-4 text-black',
            !isModern && 'p-2',
            contentClassName,
          )}
        >
          {content}
        </Tooltip.Content>
      </Tooltip.Root>
    </Tooltip.Provider>
  )
}

export default HoverableTooltip
