import { matchRoutes, useLocation } from 'react-router-dom'

// Generated by looking at various <Route> components in the app
// These don't change super often, but this will need to be maintained
// manually when they do. This felt better/simpler than trying to extract
// routes from react-router using UNSAFE_routerContext
const routes = [
  '/',
  '/.well-known/change-password',
  '/callback',
  '/login',
  '/login/reset',
  '/marketplace',
  '/org',
  '/org/members',
  '/org/overview',
  '/org/projects',
  '/org/api-credentials',
  '/org/roles/create',
  '/org/roles/:roleId',
  '/org/roles/:roleId/edit',
  '/org/roles/:roleId/assign',
  '/settings',
  '/settings/email',
  '/signup',
  '/uni',
  '/uni/:id/',
  '/uni/:id/:node/blocks/:blockId/transactions/:transactionId',
  '/uni/:id/:node/transactions',
  '/uni/:id/:node/data-importer',
  '/uni/:id/:node/warehouse-explorer',
  '/uni/:id/:node/details',
  '/uni/:id/:node/entities/',
  '/uni/:id/:node/entities/:entity',
  '/uni/:id/:node/entities/:entity/:entityId',
  '/uni/:id/:node/entities/:entity/:entityId/edit',
  '/uni/:id/:node/entities/:entity/create',
  '/uni/:id/:node/entities/:entity/edit',
  '/uni/:id/:node/explorer',
  '/uni/:id/:node/files',
  '/uni/:id/:node/files/:fileId',
  '/uni/:id/:node/metrics',
  '/uni/:id/:node/settings/',
  '/uni/:id/:node/settings/uni-settings',
  '/uni/:id/:node/settings/auth-settings',
  '/uni/:id/:node/settings/error-notifications',
  '/uni/:id/:node/settings/danger-zone',
  '/uni/:id/:node/settings/resources',
  '/uni/:id/:node/settings/success-notifications',
  '/uni/:id/:node/settings/sharing-policies',
  '/uni/:id/:node/settings/smart-contracts/',
  '/uni/:id/:node/settings/smart-contracts/:smartContractId',
  '/uni/:id/:node/settings/smart-contracts/:smartContractId/:smartContractVersionOrdinal',
  '/uni/:id/:node/settings/smart-contracts/:smartContractId/edit',
  '/uni/:id/:node/settings/smart-contracts/create',
  '/uni/:id/join',
  '/uni/:id/metrics',
  '/uni/:id/schema',
  '/uni/:id/schema/evolve',
  '/uni/:id/settings',
  '/uni/:id/transactions',
  '/create-uni',
]

// Convert to format expected by matchRoutes
const routeObjects = routes.map((path) => ({ path }))

export const useCurrentRoute = () => {
  const location = useLocation()
  const matches = matchRoutes(routeObjects, location)
  const route = matches?.[0]?.route ?? { path: null }
  return route.path
}
